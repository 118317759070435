import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

  body {
    margin: 0
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;

    /* background: #A98A74;
    background: #13110F; */
    font-family: 'Josefin Sans', sans-serif;
    font-size: 14px;
    color: #fff;
  }
  // Additional global styles...
  /* p,a {
    font-weight: 300;
  }

  h2 {
    font-weight: 400;
  } */
`;




export default GlobalStyle;
