/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

import React from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './src/styles/GlobalStyle';
import { theme } from './src/styles/theme';

export const wrapRootElement = ({ element }) => {

  return (
    <ThemeProvider theme={theme} >
      <GlobalStyle />
      {element}
    </ThemeProvider>
  );
}